import React, { useState, useContext, useEffect } from 'react';
import { Card, CardContent, Collapse, IconButton, Popover } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import appContext from '../../context/AppContext'
const ExampleWrapperSimple = (props) => {
  const { sectionHeading, sectionAlertTitle, children, onClickHandler, icon } = props;
  const AppContext = useContext(appContext);
  const [open2, setOpen2] = useState(true);
  const [hover1, setHover1] = useState(false);
  const openModal = () => {
    AppContext.chartToggle();
    AppContext.setChartData("Purchase");
    AppContext.setChartModalStatus(true);
    AppContext.setRSChart("Customer Pending", { customerPurchaseOrderUrl:true, company_id: AppContext?.user?.id });
    AppContext.setADChart("Quotation Received", { customerPurchaseOrderUrl:true,customerApproverContactId:true, company_id: AppContext?.user?.id });
    AppContext.setModalStatus("Purchase Order");
    AppContext.setTableStatus("SENT");

  }
  const openClose1 = (event) => {
    setHover1(event.currentTarget)
  }
  const handaleClose1 = () => {
    setHover1(null);
  }
  return (
    <Card className="app-card-box card-box h-100 bordered-card-box shadow-none overflow-visible">
      {sectionHeading && (
        <div className="card-header border-0">
          <div className="card-header--title font-size-xxl font-monrope text-base font-weight-bold pb-3">
            <span className='justify-content-center' style={{ position: "relative" }}>{sectionHeading}{icon && <span
              onClick={openClose1} style={{ position: "relative", top: "9px" }} className="cash-line-chart-title cursor_pointer cashflowtooltip">&nbsp;&nbsp;&nbsp;&nbsp;</span>}</span>
          </div>
          {sectionAlertTitle && <Collapse in={open2}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  className='btn-transition-none bg-transparent'
                  onClick={() => {
                    setOpen2(false);
                  }}
                >
                </IconButton>
              }
              onClick={openModal}
              className="app-alerts font-weight-semibold text-base"
              severity="error">
              {sectionAlertTitle}
            </Alert>
          </Collapse>}
          <Popover
            className='cash-flow-responsive'
            style={{
              fontFamily: 'Manrope',
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: '14px',
            }}
            open={hover1}
            anchorEl={hover1}
            onClose={handaleClose1}>
            <div className='tooltip-wrap' style={{ margin: "12px 16px" }}>
              <span
                style={{ position: "relative", top: "6px", padding: "4px", marginRight: "6px" }}
                className="cash-line-chart-title cursor_pointer">&nbsp;&nbsp;&nbsp;&nbsp;</span><span>Cashflow based on approved purchase orders.</span>
            </div>
          </Popover>
        </div>
      )}
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default ExampleWrapperSimple;
