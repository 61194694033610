import React, { useState, useContext, useEffect } from 'react';
import appContext from '../../context/AppContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { Popover, Button, List, ListItem } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getKYCstatus } from 'service';

const HeaderDots = () => {
  const AppContext = useContext(appContext);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);
  const [kycDone, setKycDone] = useState(false)
  const [pendingCount, setPendingCount] = useState([]);
  const AllDoc2 = ["PAN", "TAN", "GSTIN", "TDS/TCS Declaration", "MSME/Udyam Certificate", "Cancelled Cheque"]
  const [finalDoc, setFinalDoc] = useState(null);


  useEffect(() => {
    if (AppContext?.user)
      getKyc();
  }, [AppContext?.user])

  const getKyc = async () => {
    let res = await getKYCstatus(AppContext?.user?.id);
    let unique = [];
    let unique2 = [];
    let filter = [];
    let filter2 = [];
    if (res.data.data == null) { setFinalDoc([]) }
    else {
      res.data.data.map(i => {
        unique.push(i.documentType)
      })
      unique = unique.filter(function (item, i, ar) { return ar.indexOf(item) === i; });
      unique.map(t => {
        if (t === "pan") unique2.push("PAN")
        else if (t === "tan") unique2.push("TAN")
        else if (t === "gstin") unique2.push("GSTIN")
        else if (t === "cancelledcheque") unique2.push("Cancelled Cheque")
        else if (t === "udyam") unique2.push("MSME/Udyam Certificate")
        else if (t === "tdsdeclaration") unique2.push("TDS/TCS Declaration")
      })
      let array3 = AllDoc2.filter(function (obj) { return unique2.indexOf(obj) == -1; });
      let fArray = [];
      AllDoc2.forEach(p => {
        if (unique2.indexOf(p) > -1) {
          fArray.push({ name: p, status: "Completed" })
        } else {
          fArray.push({ name: p, status: "Pending" })
        }
      });
      fArray.map(q => {
        if (q.status == "Completed") {
          filter.push(q)
        } else if (q.status == "Pending") {
          filter2.push(q)
        }
      })
      filter = filter.concat(filter2);
      setFinalDoc(filter);
      setPendingCount(array3?.length)
      if (array3?.length === 0) {
        setKycDone(true);
      } else {
        setKycDone(false)
      }
    }
  }
  const handaleClick = (event) => {
    setAnchorEl2(event.currentTarget);
  }
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  const open3 = Boolean(anchorEl3);

  const [headerDrawerToggle, setHeaderDrawerToggle] = useState(null);

  const toogleHeaderDrawer = () => {
    setHeaderDrawerToggle(!headerDrawerToggle);
    setAnchorEl2(null);
  };
  useEffect(() => {
    document.body.classList.toggle('Model_Open', headerDrawerToggle);
  }, [headerDrawerToggle])
  return (
    <div className={clsx({
      'header-drawer-open': headerDrawerToggle
    })}>
      <div
        className="d-flex align-items-center popover-header-wrapper app-header-kyc">
        <span className="pr-3 d-none d-md-block">
          {kycDone ? <Button
            onClick={handaleClick}
            className="w-auto btn-transition-none complete-hover btn-outline-success d-inline-block shadow-none btn-header-kyc">
            <div className="d-flex align-items-center">
              <span className="pr-1 pr-xl-2">
                <i className="icon-check"></i>
              </span>
              <span className="kyc_title">
                KYC Completed
              </span>
            </div>
          </Button>
            :
            <Button
              onClick={handaleClick}
              className="w-auto btn-transition-none incomplete-hover btn-outline-danger bg-neutral-danger d-inline-block shadow-none btn-header-kyc">
              <div className="d-flex align-items-center">
                <span className="pr-1 pr-xl-2">
                  <i className="icon-info"></i>
                </span>
                <span className="kyc_title">
                  KYC Incomplete
                </span>
              </div>
            </Button>}
          <Popover
            classes={{ paper: 'popover-custom-wrapper rounded-lg popover-custom-xl app-kyc-popover testimg' }}
            open={anchorEl2}
            anchorEl={anchorEl2}
            onClose={handleClose2}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}>
            <div className="app-kyc-popover-box">
              <div className='d-flex app-kyc-popover-info'>
                {
                  pendingCount ? <> <i className="icon-info"></i>
                    <span>{pendingCount}/6 Documents are pending for KYC completion. Kindly submit them at the earliest.</span></>
                    :
                    <> <i className="icon-check2"></i>
                      <span>Your KYC is complete</span></>
                }

              </div>
              <Button onClick={toogleHeaderDrawer} className="p-0 btn-transparent btn-link btn-link-first btn-transition-none app-kyc-popover-link">
                <span>View {pendingCount ? <span>Pending</span> : <span>Completed</span>}Documents</span>
                <i className="icon-redirect"></i>
              </Button>
            </div>
          </Popover>
        </span>
        <span className="pl-1 pr-2 pr-md-3">
          <Button
            onClick={handleClick3}
            className="btn-transparent w-250 btn-link btn-transition-none btn-link-gst"
          >
            {AppContext?.user?.gstIn ? <span>
              GSTIN: <span className='_number'>{AppContext?.user?.gstIn}</span>
            </span> :
              <span>
                NAME: <span className='_number'>{(AppContext?.user?.organisationName)?.substring(0, 15)}</span>
              </span>
            }
            <span className="ml-2 _icon-wrap">
              <FontAwesomeIcon icon={['fas', 'angle-down']} />
            </span>
          </Button>
          <Popover
            classes={{ paper: 'popover-custom-wrapper rounded-lg popover-custom-md app-gst-popover' }}
            open={open3}
            anchorEl={anchorEl3}
            onClose={handleClose3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}>
            <List
              component="div"
              className="text-left bg-transparent ">
              {
                AppContext?.users?.map((p, ind) => (
                  <>
                    {p?.id && <ListItem onClick={() => { AppContext.setUser(p); handleClose3(); }} style={(p?.gstIn == AppContext?.user?.gstIn) ? { cursor: 'pointer', backgroundColor: "#FEFBEE" } : { cursor: 'pointer', fontFamily: "", }} >
                      <span style={{ fontFamily: "Roboto Mono" }} >
                        {p?.gstIn ? p?.gstIn : "-"}
                      </span>
                      <span style={{ width: '-webkit-fill-available', fontFamily: "Manrope" }} className="_label pl-3 text-right">
                        {p?.organisationName}
                      </span>
                    </ListItem>}
                  </>
                ))
              }
            </List>
          </Popover>
        </span>
      </div>

      <div className="app-drawer-content">
        <div className="app-pending-doc-drawer">
          <div className="app-pending-doc-drawer-head d-flex align-items-start justify-content-between">
            <div className="app-pending-doc-drawer-title d-flex align-items-center">
              <div className="app-pending-doc-drawer-head-icon-wrap"><i className="icon-file"></i></div>
              <h2 className="mb-0">Documents for KYC</h2>
            </div>
            <Button onClick={toogleHeaderDrawer} className="btn-transition-none btn-close shadow-none btn-link p-0">
              <i className="icon-close"></i>
              <span>Close</span>
            </Button>
          </div>

          <div className='app-pending-doc-drawer-body-wrap'>
            <PerfectScrollbar>
              <div className='app-pending-doc-drawer-body'>
                {pendingCount ? <div className='app-pending-doc-drawer-body-top d-flex'>
                  <i className='icon-info'></i>
                  <span>{pendingCount}/6 Documents are pending for KYC completion. Kindly submit them at the earliest. </span>
                </div>
                  :
                  <div></div>}

                <List className="app-doc-drawer-list">
                  {
                    finalDoc?.map((p, index) => (

                      p?.status === "Completed" ?
                        <ListItem key={index} className="app-doc-drawer-list-item d-flex align-items-center justify-content-between">
                          <span className="_name"> {p?.name}</span>
                          <div className="_status-wrap d-flex align-items-center">
                            <i className="icon _checkmark"></i>
                            <span>{p?.status}</span>
                          </div>
                        </ListItem>
                        :
                        <ListItem key={index} className="app-doc-drawer-list-item d-flex align-items-center justify-content-between">
                          <span className="_name">{p?.name}</span>
                          <div className="_status-wrap d-flex align-items-center">
                            <i className="icon _info"></i>
                            <span>{p?.status}</span>
                          </div>
                        </ListItem>
                    ))
                  }
                </List>
              </div>
            </PerfectScrollbar>
          </div>
        </div>
      </div>

      <div
        onClick={toogleHeaderDrawer}
        className={clsx('app-drawer-overlay 1', {
          'is-active': headerDrawerToggle
        })}
      />
    </div>
  );
};

export default HeaderDots;
