import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';
import Routes from './Routes';
import ScrollToTop from './utils/ScrollToTop';
import './assets/base.scss';
import history from "./utils/history";

import WithAuth from 'components/Auth/WithAuth';
const store = configureStore();
const App = () => {
  return (

    <Provider store={store}>
      <BrowserRouter basename="/" history={history}>
        <ScrollToTop>
          <WithAuth >
            <Routes />
          </WithAuth>
        </ScrollToTop>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
