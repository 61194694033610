import React, { useContext, useEffect } from 'react';

import clsx from 'clsx';

import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import { getUserAPI } from '../../service'
import HeaderDots from '../../layout-components/HeaderDots';
import HeaderUserbox from '../../layout-components/HeaderUserbox';
import appContext from '../../context/AppContext'
import projectLogo from '../../assets/images/logo.png';
import projectMobLogo from '../../assets/images/logo-sm.png';
import { useAuth0 } from "@auth0/auth0-react";
import { useViewport } from 'hooks/useViewport';
import { track, identify } from 'Services/mixpanel.service';


const Header = (props) => {
  const { user, isAuthenticated, logout, getAccessTokenSilently } = useAuth0();
  const AppContext = useContext(appContext);
  useEffect(() => {
    if (user) getAccessTokenSilentlyApi();
  }, [isAuthenticated]);

  const {isDesktop} = useViewport();

  const setExternalUserID = (phoneNumber) =>{
    return window.gonative?.onesignal.externalUserId.set({
      externalId:phoneNumber
    });
  }


  const getAccessTokenSilentlyApi = async () => {
    const token = await getAccessTokenSilently();
    identify(user?.phoneNumber,{name:user?.name, phoneNumber:user?.phoneNumber});
     /***********Auth0 not providing phone number but adding phone number to name. So added fallback**************/
    getUser(token,user?.phoneNumber || user?.name);
    track('PageView',{name:user?.name, phoneNumber:user?.phoneNumber})
    setExternalUserID(user?.phoneNumber)
  };

  const getUser = async (token, emailorphone) => {
    try {
      let resp = await getUserAPI(token, emailorphone);
      let existingUser = await sessionStorage.getItem("airisinfra_user");
      if (resp?.data?.data) AppContext.setUsers(resp?.data?.data, existingUser);
      else {
        let text = `No data found with phone number: ${emailorphone}. \Click Ok to logout.`;
        if (alert(text)) { }
        else {
          logout({ returnTo: window.location.origin });
        }
      }
    }
    catch (error) {
      let text = `No data found with phone number: ${emailorphone}. \Click Ok to logout.`;
      if (alert(text)) { }
      else {
        logout({ returnTo: window.location.origin });
      }
    }
  }

  return (
    <>
      <div
        className="app-header">
        <div className="app-header--pane">
          <NavLink
            to="/"
            className="app-sidebar-logo">
            <img
              alt="Aris Connect"
              src={projectLogo} className="d-md-block"
            />
            <img
              alt="Aris Connect"
              src={projectMobLogo} className="d-none"
            />
          </NavLink>

        </div>
        <div className="app-header--pane app-header-nav-pane desktop_kyc">
          {isDesktop ? <HeaderDots /> : null}
          <HeaderUserbox />
        </div>
      </div>
      <div className='mobile_kyc'>
      {!isDesktop ? <HeaderDots /> : null}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  headerBgTransparent: state.ThemeOptions.headerBgTransparent,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
