import React, { useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import hasPermission from 'Services/identity.service';
import PageError401 from 'components/ErrorPages/Error401';
import Loading from 'components/Loader';
import Error from 'components/ErrorPages/Error';
import { useLocation } from 'react-router-dom';
import { isProtected } from './Security';
import { identify } from 'Services/mixpanel.service';

const WithAuth = ({ children }) => {

  const { isLoading, error, isAuthenticated, loginWithRedirect, user } = useAuth0();
  let history = useLocation()


  useEffect(() => {
    if (isAuthenticated && user) {
      identify(user.phoneNumber || user?.name, { name: user?.name, email: user?.email, picture: user?.picture });
    }
  }, [isAuthenticated])

  if (error) {
    return loginWithRedirect();
  }
  if (isLoading) {
    return <Loading />
  }
  if (!isProtected(history.pathname)) {
    return children;
  }
  if (!isAuthenticated) return loginWithRedirect();

  if (isAuthenticated && !hasPermission(user)) {
    return <PageError401 />
  }

  return children

};

export default WithAuth;
