import React from 'react';
import { Grid, Button } from '@material-ui/core';
import projectLogo from '../../../assets/images/logo.png';
import { NavLink } from 'react-router-dom';
import projectMobLogo from '../../../assets/images/logo-sm.png';
import illustration2 from '../../../assets/images/illustrations/pack4/500.svg';

export default function PageError401() {
  const handleRequestAccess = () => {
    window.location.href = "https://evd5niio6at.typeform.com/to/u91zM95Y";
  }
  return (
    <>
      <div className="app-wrapper min-vh-100 bg-white">
          <div className="app-main min-vh-100">
          {/* <div
          className="page-header">
          <div className="app-header--pane">
            <NavLink
              to="/"
              className="page-sidebar-logo">
              <img
                alt="Aris Connect"
                src={projectLogo} className="d-md-block"
              />
              <img
                alt="Aris Connect"
                src={projectMobLogo} className="d-none"
              />
            </NavLink>
          </div>
      </div> */}
          <div className="app-content p-0">
            <div className="app-inner-content-layout--main">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content">
                  <Grid container spacing={0}>
                    <Grid
                      item
                      lg={12}
                      xl={12}
                      className="d-flex align-items-center">
                      <Grid item md={11} xl={9} className="mx-auto text-center">
                        <div className="py-4">
                        {/* <img
                            src={illustration2}
                            className="w-50 mx-auto d-block px-2 mb-5 img-fluid"
                            alt="..."
                          /> */}
                          <h2 className="font-size-xxxl display-1 mb-3 px-4 font-weight-bold">
                            Access Denied
                          </h2>
                          <h3 className="font-size-xxl line-height-sm font-weight-light d-block px-3 mb-3 text-black-50">
                            Looks like you do not have enough permissions to access this page.
                          </h3>
                          <p className=" px-3">
                            Please contact Aris administrator.
                          </p>
                          <Button onClick={handleRequestAccess} className="  btn-transition-none btn-warning  text-white shadow-none ">
                            <span className="logout-btn-text">Request for Access</span>
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}