import { SET_BAR_DATA, SET_SR_CHART, SET_USER, SET_USERS, SET_SR_DATA, SET_MODAL_STATUS, SET_AD_CHART } from "./AppType";
export default (state, action) => {
    const { payload, type } = action;
    switch (type) {
        case SET_USER:
            return {
                ...state,
                user: payload
            };

        case SET_USERS:
            return {
                ...state,
                users: payload
            };
        case SET_BAR_DATA: {
            return {
                ...state,
                data: payload
            }
        }

        case SET_SR_CHART: {
            return {
                ...state,
                rsChart: payload
            }
        }
        case SET_AD_CHART: {
            return {
                ...state,
                adChart: payload
            }
        }
        case SET_SR_DATA: {
            return {
                ...state,
                rsChartData: payload
            }
        }
        case SET_MODAL_STATUS: {
            return {
                ...state,
                rsChartModalStatus: payload
            }
        }


        default:
            return state;
    }
};