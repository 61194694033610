import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { Header } from '../../layout-components';

const Layout = (props) => {
  const {
    children,
    headerFixed,
    contentBackground
  } = props;

  return (
    <>
      <div
        className={clsx('app-wrapper', contentBackground, {
          'app-header-fixed': headerFixed
        })}>
        <div className="app-main">
          <Header />
          <div className="app-content">
            <div className="app-content--inner">
              <div className="app-content--inner__wrapper">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node
};

const mapStateToProps = (state) => ({
  headerFixed: state.ThemeOptions.headerFixed,
  contentBackground: state.ThemeOptions.contentBackground
});

export default connect(mapStateToProps)(Layout);
