import axios from "axios";
const axios_instance = axios.create({
    baseURL: process.env.REACT_APP_ENVIRONMENT === 'production'
        ? process.env.REACT_APP_BASE_URL_PROD
        : process.env.REACT_APP_BASE_URL,
    timeout: 30000,
});

export async function getUserAPI(token, emailorphone) {
    axios_instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return new Promise((resolve, reject) => {
        axios_instance.get('/user/details', { params: { emailorphone: emailorphone } }).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
}
export async function getPriceAdvantage(company_id) {
    return new Promise((resolve, reject) => {
        axios_instance.get('/user/price-advantage', { params: { company_id } }).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
}
export async function getFirstQuoteHours(company_id) {
    return new Promise((resolve, reject) => {
        axios_instance.get('/user/time', { params: { company_id } }).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
}
export async function getKYCstatus(company_id) {
    return new Promise((resolve, reject) => {
        axios_instance.get('/user/documents', { params: { company_id } }).then(function (response) {
            resolve(response);

        }).catch(function (error) { reject(error); })
    })
}
export async function getVendorsCount(company_id) {
    return new Promise((resolve, reject) => {
        axios_instance.get('/user/vendors-count', { params: { company_id } }).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
}
export async function getLocation(company_id) {
    return new Promise((resolve, reject) => {
        axios_instance.get('/user/address', { params: { company_id } }).then(function (response) {
            resolve(response);

        }).catch(function (error) { reject(error); })
    })
}
export async function getInvoiceAndDueBalance(company_id) {
    return new Promise((resolve, reject) => {
        axios_instance.get('/user/balance', { params: { company_id } }).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
}
export async function getQAData(company_id) {
    return new Promise((resolve, reject) => {
        axios_instance.get('/user/quotation', { params: { company_id } }).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
}
export async function getFinalPOData(company_id) {
    return new Promise((resolve, reject) => {
        axios_instance.get('/user/quotation', { params: { company_id } }).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
}
export async function getRecentlyApprovedQuotes(company_id) {
    return new Promise((resolve, reject) => {
        axios_instance.get('/user/recent-approved', { params: { company_id } }).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
}
export async function getChartModelData(params) {
    return new Promise((resolve, reject) => {
        axios_instance.get('/user/model', { params: params }).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
}

export async function getCashflowData(company_id) {
    return new Promise((resolve, reject) => {
        axios_instance.get('/user/dues', { params: { company_id } }).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
}


