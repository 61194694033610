import React, { useReducer, useState } from 'react';
import AppReducer from './AppReducer';
import AppContext from "./AppContext";
import { SET_BAR_DATA, SET_USER, SET_USERS, SET_SR_CHART, SET_POPUP, SET_SR_DATA, SET_MODAL_STATUS, SET_AD_CHART } from './AppType';
import { getChartModelData } from 'service';
const d = [
    {
        date: 1,
        approvedBy: 2
    },
    {
        date: 1,
        approvedBy: 2
    }, {
        date: 1,
        approvedBy: 2
    }, {
        date: 1,
        approvedBy: 2
    }
]

const AppState = (props) => {
    let initialState = { user: null, data: null, users: null, rsChart: { name: "", data: [] }, adChart: { name: "", data: [] }, rsChartData: { name: "", data: [] }, rsChartModalStatus: { status: false, data: [] } };
    const [state, dispatch] = useReducer(AppReducer, initialState);
    const [chartModal, setChartModal] = useState(false);
    const [modalStatus, setModalStatus] = useState("");
    const [tableStatus, setTableStatus] = useState("");

    const setUsers = (users, existingUser) => {
        if (existingUser) setUser(JSON.parse(existingUser));
        else setUser(users[0]);
        dispatch({ type: SET_USERS, payload: users });
    }

    const setUser = (user) => {
        sessionStorage.setItem("airisinfra_user", JSON.stringify(user));
        dispatch({ type: SET_USER, payload: user });
    }

    const setBarData = (data) => {
        dispatch({ type: SET_BAR_DATA, payload: data })
    }

    const setRSChart = async (name, params) => {
        let res = await getChartModelData(params);
        if (res?.data?.data === null) { dispatch({ type: SET_SR_CHART, payload: { name: name, data: null } }) }
        else {
            let uniqueObjArray = [...new Map(res?.data?.data?.map((item) => [item["id"], item])).values()];
            dispatch({ type: SET_SR_CHART, payload: { name: name, data: uniqueObjArray.reverse() } })
        }
    }

    const setADChart = async (name, params) => {
        let res = await getChartModelData(params);
        if (res?.data?.data === null) { dispatch({ type: SET_SR_CHART, payload: { name: name, data: null } }) }
        else {
            let uniqueObjArray = [...new Map(res?.data?.data?.map((item) => [item["id"], item])).values()];
            dispatch({ type: SET_AD_CHART, payload: { name: name, data: uniqueObjArray.reverse() } })
        }
    }

    const setChartData = async (name) => {

        dispatch({ type: SET_SR_DATA, payload: { name: name } })
    }
    const setChartModalStatus = async (status) => {

        dispatch({ type: SET_MODAL_STATUS, payload: { status: status } })
    }

    const chartToggle = () => {
        setChartModal(!chartModal);
    };

    return (
        <AppContext.Provider
            value={{
                user: state.user,
                users: state.users,
                data: state.data,
                chartModal,
                rsChart: state.rsChart,
                adChart: state.adChart,
                rsChartData: state.rsChartData,
                rsChartModalStatus: state.rsChartModalStatus,
                modalStatus,
                tableStatus,
                setTableStatus,
                setModalStatus,
                setUser,
                setUsers,
                setBarData,
                setChartModal,
                chartToggle,
                setRSChart,
                setChartData,
                setChartModalStatus,
                setADChart
            }}
        >
            {props.children}
        </AppContext.Provider>
    )
}
export default AppState;
