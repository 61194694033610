import React, { useState, useContext } from 'react';
import appContext from '../../context/AppContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, List, Popover, ListItem } from '@material-ui/core';
import { useAuth0 } from "@auth0/auth0-react";
const HeaderUserbox = () => {
  const { user,logout } = useAuth0();
  const user_name = user?.user_metadata?.fullName || user?.nickname;

  const AppContext = useContext(appContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutWithRedirect = () => {
    sessionStorage.removeItem("airisinfra_user");
    logout({ returnTo: window.location.origin });
  }

  const appSwitcherWithRedirect = () => {
    window.location.href = process.env.REACT_APP_PUBLIC_ARIS_DELIVERY_UI_URL + '/user-apps';
  }

  const open = Boolean(anchorEl);
  return (
    <>
      <Button
        variant="text"
        onClick={handleClick}
        className="btn-transition-none text-left p-0 bg-transparent d-flex align-items-center btn-user"
        disableRipple>
        <div className="avatar-icon-wrapper avatar-initials shadow-none mr-0">
          <div className="d-40 avatar-icon text-white bg-brand font-weight-bold">
              {user_name.charAt(0)}
          </div>
        </div>

        <div className="d-none d-xl-block">
          <span className="_name">{user_name}</span>
        </div>
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{ paper: 'popover-custom-wrapper rounded-lg popover-custom-xs popover-user' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        <List
          component="div"
          className="list-group-flush text-left bg-transparent">
          <ListItem className="popover-user-info">
            <span className="_label pb-0 text-black-50 d-block">
              Signed in as :
            </span>
            <div className="d-flex align-items-center popover-user-wrap">
              <FontAwesomeIcon
                icon={['far', 'user-circle']}
              />
              <span className='_user-name'>
              {user_name}
              </span>
            </div>
          </ListItem>
        <Button
              variant="contained"
              size="small"
              className="btn-transparent btn-link btn-transition-none btn-logout shadow-none p-0">
              <img src='/images/Appswitcher_.svg' alt='Appswitcher_icon'/>
              <span onClick={() => appSwitcherWithRedirect()} className="btn-wrapper--label">App Switcher</span>
            </Button>
          <ListItem className="d-block p-0">
            <Button
              variant="contained"
              size="small"
              className="btn-transparent btn-link btn-transition-none btn-logout shadow-none p-0">
              <img src='/images/Logout.svg' alt='Logout_icon'/>
              <span onClick={() => logoutWithRedirect()} className="btn-wrapper--label">Logout</span>
            </Button>
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

export default HeaderUserbox;
