import { Grid, Box, Container} from '@material-ui/core';
import React from 'react';

const PrivacyPolicy = () => {
  return (
    <Container component="main" maxWidth="lg">
    <Grid container spacing={3} >
      <Grid item xs={12}> 
      <h1 style={{fontSize:"48px",textAlign:"center"}} >Privacy Policy</h1>
      <p>
        Welcome to our website! We value your privacy and want to ensure that
        you understand how we collect, use, and share your personal
        information. This privacy policy applies to our website and any other
        platforms or services we provide (collectively, the "Services").
      </p>
      <h2>Information We Collect</h2>
      <p>
        When you use our Services, we may collect various types of information
        from you, including personal information such as your name, email
        address, and other contact information. We may also collect information
        about your use of our Services, such as your IP address, browser type,
        and pages visited.
      </p>
      <h2>How We Use Your Information</h2>
      <p>
        We may use the information we collect from you to provide and improve
        our Services, to communicate with you about our Services, and to
        personalize your experience. We may also use your information to
        provide you with targeted advertising or marketing communications.
      </p>

      <h2>Sharing Your Information</h2>
      <p>
        We may share your information with third-party service providers who
        help us to provide and improve our Services, such as website hosting,
        analytics, and marketing. We may also share your information with
        government authorities or law enforcement officials if required by law
        or to protect our legal rights.
      </p>
      <h2>Your Choices</h2>
      <p>
        You can choose not to provide us with certain information, but this may
        limit your ability to use some of our Services. You can also opt out of
        receiving marketing communications from us by following the
        instructions in our emails or by contacting us directly.
      </p>

      <h2>Security</h2>
      <p>
        We take reasonable measures to protect your information from loss,
        theft, and unauthorized access, disclosure, alteration, and destruction.
        However, no method of transmission over the internet or electronic
        storage is completely secure, so we cannot guarantee absolute security.
      </p>

      <h2>Changes to this Privacy Policy</h2>
      <p>
        We may update this privacy policy from time to time to reflect changes
        in our Services or applicable laws. We will notify you of any material
        changes by posting the updated policy on our website and updating the
        "Effective Date" at the top of the policy</p>
      </Grid>
      </Grid>
      </Container>
      )
};

export default PrivacyPolicy;
