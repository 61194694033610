
 import React from 'react'; 
 import { Grid, Button, CircularProgress } from '@material-ui/core';
 
const Loading = () => {
  

  return (
    <>
     <div className="app-wrapper min-vh-100 bg-white">
        <div className="app-main min-vh-100">
          <div className="app-content p-0">
            <div className="app-inner-content-layout--main">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content">
                  <Grid container spacing={0} className="min-vh-100">
                    <Grid
                      item
                      lg={12}
                      xl={12}
                      className="d-flex align-items-center">
                      <Grid item md={11} xl={9} className="mx-auto text-center">
                        <div className="py-4">
                        <CircularProgress />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loading;